<template>
    <title-bar :title="$t('navigations.tshirtSizes')"/>
    <resource-listing :resource="$EntityOptions" param-type="TSHIRT_SIZE" base-url="tshirt-sizes" add-button="messages.addNew" delete-message="messages.tshirtSizeDelete"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
